define("discourse/plugins/discourse-data-explorer/discourse/components/param-inputs-wrapper", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @hasParams}}
    <div class="query-params">
      {{#each @paramInfo as |pinfo|}}
        <ParamInput
          @params={{@params}}
          @initialValues={{@initialValues}}
          @info={{pinfo}}
          @updateParams={{@updateParams}}
        />
      {{/each}}
    </div>
  {{/if}}
  */
  {
    "id": "ArIoh7OG",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"query-params\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@params\",\"@initialValues\",\"@info\",\"@updateParams\"],[[30,4],[30,5],[30,3],[30,6]]],null],[1,\"\\n\"]],[3]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@hasParams\",\"@paramInfo\",\"pinfo\",\"@params\",\"@initialValues\",\"@updateParams\"],false,[\"if\",\"each\",\"-track-array\",\"param-input\"]]",
    "moduleName": "discourse/plugins/discourse-data-explorer/discourse/components/param-inputs-wrapper.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "param-inputs-wrapper"));
});